.login_link__d0B4a {
  position: fixed;
  top: 3.2rem;
  right: 3.2rem;
  cursor: pointer; }

.purchase-button{
    float: right;
    margin-right: 8%;
    margin-top: 11%;
    background: #444d6a;
    padding: 15px;
    border-radius: 20px;
}

.purchase-button-text{
    color: white;
}



.continue-purchase-button{
    float: left;
    margin-left: 2%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
    border-radius: 20px;
}

.continue-purchase-button-text{
    color: white;
}

.go-back-button{
    float: left;
    margin-left: 5%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
    border-radius: 20px;
}

.go-back-button-text{
    color: white;
}

.info-pay-root{
    min-height: 400px;
    display: flex;
}

.info-pay{
    font-size: 20px;
    padding: 35px 40px 40px 40px;
    float: left;
    width: 47%;
    background: white;
    margin-left: 15px;
}

.info-pay-message{
    font-size: 20px;
    padding: 35px 40px 40px 40px;
    float: right;
    margin-left: 20px;
    width: 49%;
    height: auto;
    background: aliceblue;
}

.braintree-hosted-field {
    height: 40px;
    border: 1px solid black;
    padding: 0 1rem;
}
.braintree-hosted-field.focused {
    border-color: blue;
}
.braintree-footer {
    margin-bottom: 30px;
    margin-top: 23px;
    padding: 2rem;
}
.braintree-footer button {
    padding: 1rem;
    background-color: lightGrey;
    font-size: 1.2rem;
}

.payment-steps-root{
    max-width: 650px;
    margin-left: 10%;
    margin-top: 2%;
    float: left;
    width: 420px;
}

.braintree-verify{
    margin-top: 10px;
    /* margin-bottom: 20px; */
    width: 164px;
    height: 44px;
}

.payment-summary-root{
    border-top: 10px solid black;
    max-width: 650px;
    margin-right: 7%;
    margin-top: 7%;
    float: right;
    width: 50%;
}

.checkbox-confirm{
    margin-top:20px;
}

.loading-text{
    margin-top:50px;
    margin-left: 10px;

}

.payment-summary-terms-info{
    color: #000;
    font-size: 13px;
    font-weight: 400;
    padding: 0;
    line-height: 1.8;
    margin: 0 0 40px 0;
}

.payment-summary{
    padding: 35px 40px 40px 40px;
    border: 1px solid #d0d0d3;
    border-top: none;
}

.payment-summary-billed-Summary{
    font-size: 25px;
    font-family: SharpGroteskWide,Open Sans,sans-serif;
    line-height: 1.28;
    margin-top:10px;
    margin-bottom: 10px;
}

.payment-button{
    float: right;
    margin-right: 0%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
    width: 59%;
    text-align: center;
}

.payment-button-text{
    color: white;
}

.payment-go-back-button{
    float: left;
    margin-left: 5%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
}

.payment-go-back-button-text{
    color: white;
}


.payment-summary-bill{
    color: #000;
    font-family: SharpGrotesk,Open Sans,sans-serif;
    font-size: 20px;
    line-height: 1.35;
    font-weight: 400;
    margin: 0 0 40px 0;
}

.payment-summary-headers{
    color: #000;
    font-family: SharpGrotesk,Open Sans,sans-serif;
    font-size: 20px;
    margin-top:10px;
    margin-bottom: 10px;
}

.payment-divider{
    border: none;
    height: 1px;
    background-color: #d0d0d3;
    color: #d0d0d3;
    margin-bottom: 25px;
    margin-top: 0;
}

.payment-step{
    margin-bottom: 40px;
}

.radio-input{
    margin-right: 10px;
}

.radio-input-div{
    margin-top: 10px;
    margin-left: 20px;
}

.payment-headers{
    color: #000;
    font-family: SharpGrotesk,Open Sans,sans-serif;
    font-size: 20px;
}


.label-terms{
    margin-left: 22px;
    margin-top: -18px;
    margin-bottom: -6px;
    display: block;
}

.label-terms-validation{
    margin-top: 15px;
    font-size: 12px;
    line-height: 1.91667;
    font-weight: 400;
    color: #d5001a;
}

.label-success{
    float:right;
    margin-top: 15px;
    font-size: 25px;
    line-height: 1.91667;
    font-weight: 400;
    color: green;
}

.button-description{
    /*display: block;*/
    width: 67%;
    margin-top: 30px;
    margin-left: 12px;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dOmLNM{
    background-color: #005b9f !important;
}

/* Darker background on mouse-over */
/*.btn:hover {*/
/*    background-color: RoyalBlue;*/
/*}*/

.header_navbar__1Q77l {
  display: flex;
  align-items: center;
  padding: 3.2rem; }
  .header_navbar__1Q77l.header_isFull__1YxES {
    background: white;
    border-bottom: 1px solid #F0F6FA; }

.header_logoLink__KHlb2 {
  display: inline-block; }

.header_logo___IgZZ {
  display: block;
  width: 160px;
  height: auto; }

.header_tabsWrapper__2Oe4x {
  display: flex;
  justify-content: center;
  margin-left: 3.2rem; }

.header_tab__XPedi {
  margin: 0 1.6rem; }

.header_loginLogoutWrapper__3TKsM {
  margin-left: auto; }

.footer_footer__3R7Tu {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 3.2rem; }

.footer_logo__1IJjc {
  margin: 0 0.8rem;
  width: 24px;
  height: 24px; }

@media (min-width: 768px) {
  .dashboard_outerContainer__2d7b1 {
    padding-top: 100px;
    margin: 0 auto;
    max-width: 800px !important; } }

.dashboard_innerContainer__1w0cb {
  display: flex;
  flex-direction: column;
  margin: 0 calc(0.8rem * -1); }
  @media (min-width: 768px) {
    .dashboard_innerContainer__1w0cb {
      flex-direction: row; } }

.dashboard_logo__XN2hh {
  display: block;
  width: 250px;
  margin: 0 auto 4.8rem auto; }

.dashboard_boxItem__1fD4w {
  flex: 1 1;
  margin: 0.8rem;
  background: white;
  text-align: center;
  padding: 50px; }
  @media (min-width: 768px) {
    .dashboard_boxItem__1fD4w {
      margin-top: 0;
      margin-bottom: 0; } }
  .dashboard_boxItem__1fD4w svg {
    width: 50px;
    height: 50px; }
  .dashboard_boxItem__1fD4w .fill-target {
    fill: #B8CBD9; }
  .dashboard_boxItem__1fD4w:hover .fill-target {
    fill: #0083E3; }

.dashboard_boxTitle__3Y5UH {
  margin-top: 1.6rem; }

.video-preview_preview__38grw {
  position: relative;
  cursor: pointer; }

.video-preview_previewImage__3FwMD {
  width: 100%; }

.video-preview_playIcon__3IB9G {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50px;
  height: 50px; }

.video-preview_isLiveIndicator__1IhIU {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  display: flex;
  align-items: center;
  background: white;
  padding: 0 0.8rem; }

.video-preview_isLiveIcon__3j9BE {
  display: inline-block;
  background: #00CCB8;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px; }

.video-preview_description__3nzNR {
  margin-top: 0.8rem; }

.video-preview_date__5cyeJ {
  margin-top: 0.4rem; }

.no-video_text__1-RVV {
  margin: 0.8rem; }

.video-previews_previewsContainer__3d0qM {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(0.8rem * -1); }

.video-previews_previewContainer__15g3x {
  margin: 0.8rem;
  flex: 0 0 400px; }

.media-player_media__K9b1S {
  max-width: 1152px;
  height: auto;
  margin: 0 auto; }

 {
  /* Fix the player container to take up 100% width and to calculate its height based on its children. */
  /* Fix the video container to take up 100% width and to calculate its height based on its children. */
  /* Fix the media-control element to take up the entire size of the player. */
  /* Fix the video element to take up 100% width and to calculate its height based on its natural aspect ratio. */ }
  [data-player] {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0; }
  [data-player] .container[data-container] {
    width: 100%;
    height: auto;
    position: relative; }
  [data-player] .media-control[data-media-control] {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  [data-player] video {
    position: relative;
    display: block;
    width: 100%;
    height: auto; }

.streamviewer_mediaContent__2in2s {
  margin: 0 auto;
  max-width: 1152px;
  padding-top: 3.2rem; }

.streamviewer_bottomWrapper__nWya3 {
  display: flex;
  align-items: center;
  margin-top: 0.8rem; }

.streamviewer_buttonsWrapper__1LWRn {
  display: flex;
  align-items: center;
  margin-left: auto; }

.streamviewer_buttonMargin__1xMkA {
  margin-left: auto !important; }

.streamviewer_button__1XLpy {
  margin: 0 1.6rem; }

/*
# Mobile
only screen and (min-width: 480px)

# Tablet
  only screen and (min-width: 768px)

# Desktop
  only screen and (min-width: 992px)

# Huge
  only screen and (min-width: 1280px)
*/

/* General + web css */

/* Forms */

.left.menu{
    font-size: 15px;
}

.ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover {
    color: white;
}

.form-wrapper {
    position: relative;
    padding: 30px;
    margin: 20px auto;
    opacity: 0.85;
    background-color: #fff;
    min-height: 300px;
}

.recorder {
    color: red;
}

.recorder-box {
    border: 1px solid gray;
    background-color: rgb(207, 198, 198);
    height: 200px;
}

.btn-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.customSegment {
    box-shadow: none !important;
    border: none !important;
    display: flex;
}

.customDate {
    align-items: flex-end;
    justify-content: flex-end;
}

.customDate div {
    margin-right: 10px;
}

.customIcon {
    margin-top: 12px !important;
}

.grey {
    background-color: #efefef;
    padding: 20px;
}

.loginForm {
    margin: 0 auto !important;
    width: 50%;
}

/* Terms and conditions page(both web and tablet) */
.tos-page {
    padding-top: 2rem;
}

.tos-page #tosIframe {
    width: 100%;
}


.gray {
  color: #F0F6FA; }

.grayer {
  color: #B8CBD9; }

.grayest {
  color: rgba(0, 45, 77, 0.5); }

.on-dark-background-text {
  color: white; }

.on-dark-background-link {
  text-decoration: underline;
  color: white; }
  .on-dark-background-link:hover {
    text-decoration: underline; }

.size-1 {
  font-size: 4rem; }

.size-2 {
  font-size: 3.2rem; }

.size-3 {
  font-size: 2.4rem; }

.size-4 {
  font-size: 2rem; }

.size-5 {
  font-size: 1.6rem; }

.size-6 {
  font-size: 1rem; }

.shadow {
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25); }

.rounded {
  border-radius: 20px; }

.container {
  padding: 3.2rem; }

.pointer {
  cursor: pointer; }

.web-full-background, #firebaseui_container {
  background-image: url(/static/media/christopher-burns-200321.8235b176.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%; }

.button {
  padding: 0.8rem 1.6rem;
  border: 2px solid #B8CBD9;
  border-radius: 25px; }
  .button.on-dark-background {
    border: 2px solid white;
    color: white; }

.icon-button {
  display: flex;
  align-items: center; }
  .icon-button:hover .fill-target {
    fill: #0083E3; }
  .icon-button:hover .stroke-target {
    stroke: #0083E3; }
  .icon-button .icon-button-text {
    margin-left: 0.8rem; }

:root {
  font-size: 10px; }

body {
  font-size: 1.6rem; }

h1,
.h1 {
  font-size: 4rem; }

h2,
.h2 {
  font-size: 3.2rem; }

h3,
.h3 {
  font-size: 2.4rem; }

h4,
.h4 {
  font-size: 2rem; }

body {
  color: black; }

a {
  color: black; }
  a:hover {
    color: #0083E3; }

/* Firebase UI overrides */
#firebaseui_container {
  padding-top: 100px; }

.firebaseui-title {
  font-size: 28px !important; }

.firebaseui-tos.firebaseui-tospp-full-message {
  margin-bottom: 16px !important; }

.firebaseui-card-content .firebaseui-input {
  /*font-size: 40px !important*/
  line-height: 3 !important;
  font-size: 16px !important; }

/*font-size: 25px !important*/
/*top: 40px !important*/
.firebaseui-id-submit,
.firebaseui-id-secondary-link {
  font-size: 18px !important;
  height: 48px !important; }

.firebaseui-form-actions .firebaseui-id-submit {
  background: #0083E3 !important; }

