@import '../../../styles/variables';

.navbar {
  display: flex;
  align-items: center;
  padding: $margin-three;

  &.isFull {
    background: white;
    border-bottom: 1px solid $gray; } }

.logoLink {
  display: inline-block; }

.logo {
  display: block;
  width: 160px;
  height: auto; }

.tabsWrapper {
  display: flex;
  justify-content: center;
  margin-left: $margin-three; }

.tab {
  margin: 0 $margin-two; }

.loginLogoutWrapper {
  margin-left: auto; }
