@import '../../../styles/variables';

.outerContainer {

  @media (min-width: $tablet) {
    padding-top: 100px;
    margin: 0 auto;
    max-width: 800px !important; } }

.innerContainer {
  display: flex;
  flex-direction: column;
  margin: 0 calc(#{$margin-one} * -1);

  @media (min-width: $tablet) {
    flex-direction: row; } }

.logo {
  display: block;
  width: 250px;
  margin: 0 auto $margin-four auto; }

.boxItem {
  flex: 1;
  margin: $margin-one;
  background: white;
  text-align: center;
  padding: 50px;

  @media (min-width: $tablet) {
    margin-top: 0;
    margin-bottom: 0; }

  & :global(svg) {
    width: 50px;
    height: 50px; }

  & :global(.fill-target) {
    fill: $grayer; }

  &:hover :global(.fill-target) {
    fill: $primary; } }

.boxTitle {
  margin-top: $margin-two; }
