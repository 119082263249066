@import '../../../styles/variables';

.previewsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(#{$margin-one} * -1); }

.previewContainer {
  margin: $margin-one;
  flex: 0 0 400px; }
