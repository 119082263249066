.purchase-button{
    float: right;
    margin-right: 8%;
    margin-top: 11%;
    background: #444d6a;
    padding: 15px;
    border-radius: 20px;
}

.purchase-button-text{
    color: white;
}



.continue-purchase-button{
    float: left;
    margin-left: 2%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
    border-radius: 20px;
}

.continue-purchase-button-text{
    color: white;
}

.go-back-button{
    float: left;
    margin-left: 5%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
    border-radius: 20px;
}

.go-back-button-text{
    color: white;
}

.info-pay-root{
    min-height: 400px;
    display: flex;
}

.info-pay{
    font-size: 20px;
    padding: 35px 40px 40px 40px;
    float: left;
    width: 47%;
    background: white;
    margin-left: 15px;
}

.info-pay-message{
    font-size: 20px;
    padding: 35px 40px 40px 40px;
    float: right;
    margin-left: 20px;
    width: 49%;
    height: auto;
    background: aliceblue;
}

.braintree-hosted-field {
    height: 40px;
    border: 1px solid black;
    padding: 0 1rem;
}
.braintree-hosted-field.focused {
    border-color: blue;
}
.braintree-footer {
    margin-bottom: 30px;
    margin-top: 23px;
    padding: 2rem;
}
.braintree-footer button {
    padding: 1rem;
    background-color: lightGrey;
    font-size: 1.2rem;
}

.payment-steps-root{
    max-width: 650px;
    margin-left: 10%;
    margin-top: 2%;
    float: left;
    width: 420px;
}

.braintree-verify{
    margin-top: 10px;
    /* margin-bottom: 20px; */
    width: 164px;
    height: 44px;
}

.payment-summary-root{
    border-top: 10px solid black;
    max-width: 650px;
    margin-right: 7%;
    margin-top: 7%;
    float: right;
    width: 50%;
}

.checkbox-confirm{
    margin-top:20px;
}

.loading-text{
    margin-top:50px;
    margin-left: 10px;

}

.payment-summary-terms-info{
    color: #000;
    font-size: 13px;
    font-weight: 400;
    padding: 0;
    line-height: 1.8;
    margin: 0 0 40px 0;
}

.payment-summary{
    padding: 35px 40px 40px 40px;
    border: 1px solid #d0d0d3;
    border-top: none;
}

.payment-summary-billed-Summary{
    font-size: 25px;
    font-family: SharpGroteskWide,Open Sans,sans-serif;
    line-height: 1.28;
    margin-top:10px;
    margin-bottom: 10px;
}

.payment-button{
    float: right;
    margin-right: 0%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
    width: 59%;
    text-align: center;
}

.payment-button-text{
    color: white;
}

.payment-go-back-button{
    float: left;
    margin-left: 5%;
    margin-top: 3%;
    background: #444d6a;
    padding: 15px;
}

.payment-go-back-button-text{
    color: white;
}


.payment-summary-bill{
    color: #000;
    font-family: SharpGrotesk,Open Sans,sans-serif;
    font-size: 20px;
    line-height: 1.35;
    font-weight: 400;
    margin: 0 0 40px 0;
}

.payment-summary-headers{
    color: #000;
    font-family: SharpGrotesk,Open Sans,sans-serif;
    font-size: 20px;
    margin-top:10px;
    margin-bottom: 10px;
}

.payment-divider{
    border: none;
    height: 1px;
    background-color: #d0d0d3;
    color: #d0d0d3;
    margin-bottom: 25px;
    margin-top: 0;
}

.payment-step{
    margin-bottom: 40px;
}

.radio-input{
    margin-right: 10px;
}

.radio-input-div{
    margin-top: 10px;
    margin-left: 20px;
}

.payment-headers{
    color: #000;
    font-family: SharpGrotesk,Open Sans,sans-serif;
    font-size: 20px;
}


.label-terms{
    margin-left: 22px;
    margin-top: -18px;
    margin-bottom: -6px;
    display: block;
}

.label-terms-validation{
    margin-top: 15px;
    font-size: 12px;
    line-height: 1.91667;
    font-weight: 400;
    color: #d5001a;
}

.label-success{
    float:right;
    margin-top: 15px;
    font-size: 25px;
    line-height: 1.91667;
    font-weight: 400;
    color: green;
}

.button-description{
    /*display: block;*/
    width: 67%;
    margin-top: 30px;
    margin-left: 12px;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dOmLNM{
    background-color: #005b9f !important;
}

/* Darker background on mouse-over */
/*.btn:hover {*/
/*    background-color: RoyalBlue;*/
/*}*/
