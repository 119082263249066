@import '../../../styles/variables';

.mediaContent {
  margin: 0 auto;
  max-width: $media-player-max-width;
  padding-top: $margin-three; }

.bottomWrapper {
  display: flex;
  align-items: center;
  margin-top: $margin-one; }

.buttonsWrapper {
  display: flex;
  align-items: center;
  margin-left: auto; }

.buttonMargin {
  margin-left: auto !important; }

.button {
  margin: 0 $margin-two; }
