@import '../../../styles/variables';

.previewWrapper {}

.preview {
  position: relative;
  cursor: pointer; }

.previewImage {
  width: 100%; }

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px; }

.isLiveIndicator {
  position: absolute;
  top: $margin-two;
  right: $margin-two;
  display: flex;
  align-items: center;
  background: white;
  padding: 0 $margin-one; }

.isLiveIcon {
  display: inline-block;
  background: $live;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px; }

.description {
  margin-top: $margin-one; }

.date {
  margin-top: $margin-one / 2; }
