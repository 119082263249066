@import '../../../styles/variables';

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: $margin-three; }

.logo {
  margin: 0 $margin-one;
  width: 24px;
  height: 24px; }
