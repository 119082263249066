/*
# Mobile
only screen and (min-width: 480px)

# Tablet
  only screen and (min-width: 768px)

# Desktop
  only screen and (min-width: 992px)

# Huge
  only screen and (min-width: 1280px)
*/

/* General + web css */

/* Forms */

.left.menu{
    font-size: 15px;
}

.ui.link.menu .item:hover, .ui.menu .dropdown.item:hover, .ui.menu .link.item:hover, .ui.menu a.item:hover {
    color: white;
}

.form-wrapper {
    position: relative;
    padding: 30px;
    margin: 20px auto;
    opacity: 0.85;
    background-color: #fff;
    min-height: 300px;
}

.recorder {
    color: red;
}

.recorder-box {
    border: 1px solid gray;
    background-color: rgb(207, 198, 198);
    height: 200px;
}

.btn-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.customSegment {
    box-shadow: none !important;
    border: none !important;
    display: flex;
}

.customDate {
    align-items: flex-end;
    justify-content: flex-end;
}

.customDate div {
    margin-right: 10px;
}

.customIcon {
    margin-top: 12px !important;
}

.grey {
    background-color: #efefef;
    padding: 20px;
}

.loginForm {
    margin: 0 auto !important;
    width: 50%;
}

/* Terms and conditions page(both web and tablet) */
.tos-page {
    padding-top: 2rem;
}

.tos-page #tosIframe {
    width: 100%;
}

