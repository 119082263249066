
// Colors
$primary: #0083E3;
$gray: #F0F6FA;
$grayer: #B8CBD9;
$grayest: rgba(0, 45, 77, 0.5);
$live: #00CCB8;

// Text colors
$main-text-color: black;
$on-dark-background-text-color: white;

// Margins
$margin-one: 0.8rem;
$margin-two: 1.6rem;
$margin-three: 3.2rem;
$margin-four: 4.8rem;
$margin-five: 6.4rem;

// Font sizes
$size-1: 4rem;
$size-2: 3.2rem;
$size-3: 2.4rem;
$size-4: 2rem;
$size-5: 1.6rem;
$size-6: 1rem;

// Breakpoints
$tablet: 768px;

// Misc
$border-radius: 20px;
$box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);

// TODO This should probably be refactored so that it's local only to the player component
$media-player-max-width: 1152px;
