@import 'variables';

 :root {
  font-size: 10px; }

body {
  font-size: $size-5; }

h1,
.h1 {
  font-size: $size-1; }

h2,
.h2 {
  font-size: $size-2; }

h3,
.h3 {
  font-size: $size-3; }

h4,
.h4 {
  font-size: $size-4; }
