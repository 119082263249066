@import 'variables';
@import 'utils';
@import 'typography';

body {
  color: $main-text-color; }

a {
  color: $main-text-color;

  &:hover {
    color: $primary; } }

// react-slack-feedback
// TODO activating styling below aligns button with page padding, but it messes with the popup positioning

//.dOmLNM
//  right: $margin-three
//  bottom: $margin-three
//
//.fprCaL
//  margin: 0
//  bottom: 0

/* Firebase UI overrides */
#firebaseui_container {
  @extend .web-full-background;
  padding-top: 100px; }

.firebaseui-title {
  font-size: 28px !important; }

.firebaseui-tos.firebaseui-tospp-full-message {
  margin-bottom: 16px !important; }

.firebaseui-card-content .firebaseui-input {
  /*font-size: 40px !important*/
  line-height: 3 !important;
  font-size: 16px !important; }

.firebaseui-card-content .firebaseui-label {}
/*font-size: 25px !important*/
/*top: 40px !important*/

.firebaseui-id-submit,
.firebaseui-id-secondary-link {
  font-size: 18px !important;
  height: 48px !important; }

.firebaseui-form-actions {

  .firebaseui-id-submit {
    background: $primary !important; } }
