@import 'variables';

// General
.gray {
  color: $gray; }

.grayer {
  color: $grayer; }

.grayest {
  color: $grayest; }

.on-dark-background-text {
  color: $on-dark-background-text-color; }

.on-dark-background-link {
  text-decoration: underline;
  color: $on-dark-background-text-color;

  &:hover {
    text-decoration: underline; } }

// Font sizes
.size-1 {
  font-size: $size-1; }

.size-2 {
  font-size: $size-2; }

.size-3 {
  font-size: $size-3; }

.size-4 {
  font-size: $size-4; }

.size-5 {
  font-size: $size-5; }

.size-6 {
  font-size: $size-6; }

// Font weights

// Misc
.shadow {
  box-shadow: $box-shadow; }

.rounded {
  border-radius: $border-radius; }

.container {
  padding: $margin-three; }

.pointer {
  cursor: pointer; }

.web-full-background {
  background-image: url("../assets/images/christopher-burns-200321.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%; }

// Visual components
.button {
  padding: $margin-one $margin-two;
  border: 2px solid $grayer;
  border-radius: 25px;

  &.on-dark-background {
    border: 2px solid white;
    color: $on-dark-background-text-color; } }

.icon-button {
  display: flex;
  align-items: center;

  &:hover :global(.fill-target) {
    fill: $primary; }

  &:hover :global(.stroke-target) {
    stroke: $primary; }

  .icon-button-text {
    margin-left: $margin-one; } }
